<template>
  <div class="md-login-pages">
    <div class="box">
      <!-- <div class="title m-t-30 m-b-30">欢迎登录</div> -->
      <el-tabs v-model="userType" @tab-click="handleTabClick" style="flex: 1;display: flex;flex-direction: column;">
        <el-tab-pane label="个人登录" name="02">
          <el-form :model="loginForm" label-position="top" :rules="rules" ref="loginForm" label-width="100px"
            class="login-form">
            <p class="label">账号</p>
            <el-form-item prop="username">
              <el-input v-model="loginForm.username" autocomplete="new-password" ref="username" :maxlength="20"
                placeholder="请输入账号" />
            </el-form-item>
            <p class="label">密码</p>
            <el-form-item prop="password">
              <el-input  show-password v-model="loginForm.password" autocomplete="new-password" type="password" ref="password"
                placeholder="请输入密码" />
            </el-form-item>
            <el-form-item>
              <div class="login-button function-primary-btn" @click="postLogin">登录</div>
            </el-form-item>
            <el-form-item v-if="isLogin">
              <div class="bottom">
                <div class="register" type="text" @click="toPage('/register')">还没账号，去注册</div>
                <!-- <div class="line"></div>
                <div class="find" @click="toPage('/forget')">找回密码</div> -->
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="机构登录" name="01">
          <el-form :model="loginForm" label-position="top" :rules="rules" ref="loginForm" label-width="100px"
            class="login-form">
            <p class="label">账号</p>
            <el-form-item prop="username">
              <el-input v-model="loginForm.username" autocomplete="new-password" ref="username" :maxlength="20"
                placeholder="请输入账号" />
            </el-form-item>
            <p class="label">密码</p>
            <el-form-item prop="password">
              <el-input  show-password v-model="loginForm.password" autocomplete="new-password" type="password" ref="password"
                placeholder="请输入密码" />
            </el-form-item>
            <el-form-item>
              <div class="login-button function-primary-btn" @click="postLogin">登录</div>
            </el-form-item>
            <el-form-item v-if="isLogin">
              <div class="bottom">
                <div class="register" type="text" @click="toPage('/register')">还没账号，去注册</div>
                <!-- <div class="line"></div>
                <div class="find" @click="toPage('/forget')">找回密码</div> -->
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="统一登陆" name="03">
          <div class="login-forms">
            <div class="login-button function-primary-btn el-buttons" @click="goEdu">点击使用EUD统一认证</div>
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { postLogin, getInfo } from '@/axios/user'
// import { loginTime } from '@/utils/index'
export default {
  data() {
    // const check = /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])).*$/
    // 密码校验
    // const validateNewPwd = async (rule, value, callback) => {
    //   console.log(value.length)
    //   if (!value) {
    //     callback(new Error('登录密码不可为空'))
    //   } else if (value.length < 8) {
    //     callback(new Error('登录密码至少8位'))
    //   } else if (value.length > 16) {
    //     callback(new Error('登录密码至多16位'))
    //   } else if (!check.test(value)) {
    //     callback(new Error('请输入8-16位数字+英文大小写+特殊字符的密码'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      userType: '02',
      loginForm: {
        username: '',
        password: ''
      },
      mima: false,
      codeImg: null,
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        // password: [
        //   { required: true, validator: validateNewPwd, trigger: 'blur' }
        // ],
      }, // 重置密码验证
      // 验证码
      sendContent: '获取验证码',
      smsWait: 0,
      smsSwitch: false
    }
  },
  watch: {
    userType() {
      this.loginForm.username = ''
      this.loginForm.password = ''
    }
  },
  computed: {
    isLogin() {
      return window.localStorage.getItem('isLogin')
    },
  },
  created() {
    // document.onkeydown = (e) => {
    //   const key = window.event.keyCode
    //   if (key === 13 && !localStorage.getItem('Authorization')) this.postLogin()
    // }
  },
  methods: {
    handleTabClick(tab, e) {
      console.log(tab, e)
    },
    goEdu() {
      window.open('https://cas.swcvc.edu.cn/cas/WEB/index.html?service=https://www.aikoolearn.com/cas#/index', '_blank')
    },
    // 发送验证码
    // async sendSms () {
    //   if (this.smsWait !== 0 || this.smsSwitch) {
    //     return false
    //   }
    //   if (this.loginForm.username === '' || this.loginForm.password === '') {
    //     this.$message.error('请输入账号和密码')
    //     return false
    //   }
    //   const params = {
    //     businessType: '9cafcf69',
    //     password: this.loginForm.password,
    //     username: this.loginForm.username
    //   }
    //   this.smsSwitch = true
    //   const res = await postCaptchaSms(params)
    //   this.smsSwitch = false
    //   if (res.code === 200) {
    //     this.$alert(`验证码已发送到您手机号为${res.data.phone}上，请注意查收。`, '提示', {
    //       confirmButtonText: '确定',
    //       showClose: false
    //     }).then(() => {
    //       this.loginForm.uuid = res.data.uuid
    //       this.$refs.captcha.focus()
    //     }).catch(() => { })
    //     this.smsWait = 60
    //     this.waitSms()
    //     this.smsInterval = setInterval(() => {
    //       this.waitSms()
    //     }, 1000)
    //   }
    // },
    // waitSms () {
    //   this.smsWait--
    //   if (this.smsWait === 0) {
    //     clearInterval(this.smsInterval)
    //     this.sendContent = '重新获取'
    //   } else {
    //     this.sendContent = this.smsWait + 's'
    //   }
    // },
    // 登录
    async postLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          window.localStorage.removeItem('Authorization')
          const res = await postLogin({
            ...this.loginForm,
            userType: this.userType
          })
          if (res.code === 200) {
            // 跳转到第一个路由页面
            localStorage.setItem('Authorization', res.token) // 存储登录token
            localStorage.setItem('isLogin', true)
          
            // localStorage.setItem('refreshToken', res.data.refreshToken)
            // loginTime()
            if (localStorage.getItem('Authorization')) {
              this.getPath() // 获取跳转链接
            }
          }
        }
      })
    },
    // 获取登录后跳转的链接
    async getPath() {
      // 获取用户信息
      const res = await getInfo()
      if (res.code === 200) {
        console.log(res)
        localStorage.setItem('userInfo', JSON.stringify(res.user))
        sessionStorage.setItem('deptId', res.dept.deptId)
        this.$store.state.user = res.user
        this.$message.success('登录成功')
        this.$router.push({
          path: '/home'
        })
      }
    },
    // 页面跳转
    toPage(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 460px;
  height: 441px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 76, 161, 0.1);
  border-radius: 8px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.md-login-pages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  // background: url("../assets/login-bg.png") no-repeat center;
  // background-size: 100% 100%;
}

.login-form {
  width: 368px;
  margin: 10px auto 0;
  // padding-top: 10px;
}

.login-submit {
  width: 368px;
  text-align: center;
}

.el-form-item {
  margin-bottom: 20px;
}

/deep/.el-input__inner {
  height: 44px;

  &::placeholder {
    color: #999;
  }
}

.captcha-box {
  position: absolute;
  width: 105px;
  right: 0;
  top: 15px;
  border-left: 1px solid #d9d9d9;
  cursor: pointer;

  .captcha-btn {
    height: 16px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: @md-primary-color;
  }
}

.code {
  position: relative;
}

.title {
  font-weight: 600;
  color: @md-primary-color;
  height: 33px;
  line-height: 33px;
  font-size: 24px;
}

.label {
  font-size: 14px;
  color: #333;
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;
}

.login-button {
  width: 100%;
  height: 40px;
  background: @md-primary-color;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  margin-top: 17px;
}

.line {
  width: 1px;
  height: 16px;
  background: #d9d9d9;
}

.bottom {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;

  .find {
    color: #333;
    cursor: pointer;

    &:hover {
      color: @md-primary-color;
    }
  }

  .register {
    color: #333;
    cursor: pointer;

    &:hover {
      color: @md-primary-color;
    }
  }

  .line {
    margin: 0 20px;
  }
}

/deep/.el-form-item {
  margin-bottom: 28px !important;
}

/deep/ .el-form-item__error {
  margin-top: 5px;
  color: #e00f16;
  padding-left: 18px;
  font-size: 12px;

  &::before {
    position: absolute;
    left: 0;
    top: 2px;
    z-index: 999;
    width: 12px;
    height: 12px;
    display: inline-block;
    content: '';
    // background: url('../assets/icon_fail@2x.png') no-repeat center;
    background-size: 100% 100%;
  }
}

/deep/ .el-form-item__content {
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: @md-primary-color !important;
  }
}

/deep/ .el-tabs__active-bar {
  background-color: @md-primary-color;
}

/deep/ .el-tabs__item.is-active {
  color: @md-primary-color;
}

/deep/ .el-tabs__item:hover {
  color: @md-primary-color;
}

.login-forms {
  width: 368px;
  margin: 10px auto 0;
  height: 200px;
  display: flex;
  align-items: center;
}

.el-button {
  position: absolute;
  // left:21%;
  top: 45%;
}</style>
